<template>
  <div>
    <van-sticky>
      <div style="background-color:white;">
        <van-collapse v-model="activeNames">
          <van-collapse-item :title="materiel.materielCode" name="1">
            <!-- <van-cell title="物料类型" :value="materiel.materielType"></van-cell> -->
            <van-cell title="来料时间" :value="materiel.materielTime"></van-cell>
            <van-field label="地址" v-model="materiel.address" input-align="right"></van-field>
            <van-field label="质量" v-model.number="materiel.quality" type="number" input-align="right"></van-field>
          </van-collapse-item>
        </van-collapse>

        <el-row type="flex" justify="space-around">
          <el-col :span="6">
            <van-button type="info" block plain size="small" icon="checked" @click="updateModel" :loading="loading3">保存来料</van-button>
          </el-col>
          <el-col :span="6">
            <van-button type="info" block plain size="small" icon="bag" to="../WapMaterielIndex" :loading="loading2">来料管理</van-button>
          </el-col>
          <el-col :span="6">
            <van-button type="info" block icon="add" plain size="small" :loading="loading1" @click="createSpecimen">生成试件</van-button>
          </el-col>
        </el-row>
        <van-divider>试件码</van-divider>
      </div>
    </van-sticky>
    <van-cell-group v-if="specimenList.length>0">
      <van-cell v-for="(item,index) in specimenList" :key="index" :title="item.signNumber">
        <template #default>
          <van-tag type="warning">无记录</van-tag>
        </template>
      </van-cell>
    </van-cell-group>
    <van-empty v-else description="请点击按钮生成试件"></van-empty>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
Vue.use(Vant);

export default {
  props: ["materielId"],
  data() {
    return {
      activeNames: ["1"],
      materiel: {},
      specimenList: [],
      loading1: false,
      loading2: false,
      loading3: false,
    };
  },

  methods: {
    updateModel() {
      let that = this;
      that.loading3 = true;
      that.axios
        .post("Materiel/UpdateModel", that.materiel)
        .then(function (response) {
          that.loading3 = false;
          that.materiel = response.data.data;
          that.$notify({ type: "success", message: "保存成功" });
        });
    },
    createSpecimen() {
      let that = this;
      if (that.materiel.isDelete) {
        that.$notify("请保存来料");
      } else {
        that.loading1 = true;
        that.axios
          .post("Specimen/AddModel", {
            materielId: parseInt(that.materielId),
          })
          .then(function (response) {
            that.loading1 = false;
            that.specimenList.unshift(response.data.data);
            that.popupSpecimenTypeVisible = false;
          });
      }
    },
    getMaterielModel() {
      let that = this;
      return that.axios.post("Materiel/GetModel", {
        onlyInt: parseInt(that.materielId),
      });
    },
    getSpecimenList() {
      let that = this;
      return that.axios.post("Specimen/GetListByMaterielId", {
        onlyInt: parseInt(that.materielId),
      });
    },
    handleScroll(e) {
      let that = this;
      if (e.isFixed) {
        that.activeNames = [];
      } else {
        that.activeNames = ["1"];
      }
    },
  },
  mounted() {
    document.title = "生成来料";
    let that = this;
    that.axios.all([that.getMaterielModel(), that.getSpecimenList()]).then(
      that.axios.spread(function (response1, response2) {
        that.materiel = response1.data.data;
        that.specimenList = response2.data.data;
      })
    );
  },
};
</script>

<style>
.test
{
    
}
</style>